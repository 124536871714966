
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}



.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0c4693;
    padding: 15px;
}

.logo img {
    width: 70px;
    height: auto;
}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    margin: 0 25px;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.nav-links a:hover {
    color: #cbcbcb;
}


h1 {
    text-align: center;
    margin: 20px 0;
}

.team-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-row {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
    flex-wrap: wrap;
    gap: 60px; 
}

.team-member {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    margin: 40px;
    text-align: center;
    width: 220px;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.team-member img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.team-member h2 {
    font-size: 1.2rem;
    margin: 10px 0;
}

.team-member p {
    font-size: 0.9rem;
    margin: 0;
    padding-bottom: 50px; 
}


.social-links {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.social-links a img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease-in-out; /* Adding ease-in-out for smooth scaling */
}

.social-links a:hover img {
    transform: scale(1.4); /* On hover, scale the image */
}
