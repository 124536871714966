@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'CustomFont';
    src: url('../public/customfont.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

.ul{padding-left: 0px !important;
  }
